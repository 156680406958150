@use "../../Styles/mixins" as responsive;

.contact {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    @include responsive.tablet {
        padding: 2.25rem;
        padding-top: 0;
    }

    @include responsive.desktop {
        padding: 0 5rem;
    }

    &__header {
        padding-top: .5rem;

        @include responsive.tablet {
            padding-top: .75rem;
        }
        
        &-title {
            font-size: 2rem;
            color: #F1F0EA;
            text-align: center;
            font-weight: 700;

            @include responsive.tablet {
                font-size: 3rem;
            }

            @include responsive.desktop {
                font-size: 4rem;
            }
        }
    }

    &__form-container {
        padding: 0 1rem;
        display: flex;
        flex-direction: column;

        @include responsive.tablet {
            flex-direction: row;
            gap: 2.25rem;
        }

        @include responsive.desktop {
            gap: 4rem;
        }
     
        &__form {
            padding-top: 1rem;
            display: flex;
            flex-direction: column;
            width: 100%;

            @include responsive.tablet {
                width: 65%;
            }


            &-title {
                font-size: 1.25rem;
                font-weight: 500;
                color: #F1F0EA;

                @include responsive.tablet {
                    font-size: 2rem;
                }
                
                @include responsive.desktop {
                    font-size: 2.25rem;
                }
            }
    
            &-details {
                padding: 1rem 0;
                font-size: .875rem;
                color: #F1F0EA;

                @include responsive.tablet {
                    font-size: 1rem;
                }

                @include responsive.desktop {
                    font-size: 1.375rem;
                    padding: 1.5rem 0;
                }
            }

            &__data {
                display: flex;
                flex-direction: column;

                @include responsive.desktop {
                    flex-direction: row;
                    gap: 1rem;
                }

                &__input-container {
                    position: relative;
                    width: 100%;
                    margin-bottom: 1.25rem;
                    
    
                    &-input {
                        position: relative;
                        width: 100%;
                        padding: .625rem 0;
                        background: transparent;
                        border: none;
                        border-bottom: 2px solid #F1F0EA;
                        outline: none;
                        color: #F1F0EA;
                        font-size: 1rem;
                        letter-spacing: .0313rem;
                    }
    
                    &-input:valid ~ &-label,
                    &-input:focus ~ &-label {
                        font-size: .875rem;
                        transform: translateY(-1.375rem);
    
                        @include responsive.desktop {
                            font-size: 1rem;
                        }
                    }
    
                    &-label {
                        position: absolute;
                        left: 0;
                        padding: .625rem 0;
                        pointer-events: none;
                        color: #F1F0EA;
                        transition: 0.5s;
                        font-size: 1.125rem;
    
                        @include responsive.desktop {
                            font-size: 1.375rem;
                        }
                    }
                }
            }


            &__input-message {
                position: relative;
                width: 100%;
                margin-bottom: 1.25rem;

                &-input {
                    position: relative;
                    width: 100%;
                    height: 9.375rem;
                    padding: .625rem 0;
                    background: transparent;
                    border: none;
                    border-bottom: 2px solid #F1F0EA;
                    outline: none;
                    color: #F1F0EA;
                    font-size: 1rem;
                    letter-spacing: .0313rem;
                    resize: none;
                }

                &-input:valid ~ &-label,
                &-input:focus ~ &-label {
                    font-size: .875rem;
                    transform: translateY(-1.375rem);

                    @include responsive.desktop {
                        font-size: 1rem;
                    }
                }

                &-label {
                    position: absolute;
                    left: 0;
                    padding: .625rem 0;
                    pointer-events: none;
                    color: #F1F0EA;
                    transition: 0.5s;
                    font-size: 1.125rem;

                    @include responsive.desktop {
                        font-size: 1.375rem;
                    }
                }
            }

            &__submit {
                width: 9.375rem;
                padding: .625rem;
                background-color: #F1F0EA;
                border: 1px solid #171D25;
                color: #171D25;
                font-size: 1.125rem;
                border-radius: 1.25rem;

                @include responsive.desktop {
                    font-size: 22px;
                }

            }
        }

        &__details {
            display: flex;
            flex-direction: column;
            padding-top: 1rem;

            @include responsive.tablet {
                gap: 3rem;
                width: 35%;
            }

            &__email {
                height: 6.25rem;
                display: flex;
                flex-direction: column;
                gap: .625rem;
        
                @include responsive.tablet {
                    height: 9.375rem;
                }

                &-title {
                    color: #F1F0EA;
                    font-size: 1.375rem;
                    font-weight: 500;

                    @include responsive.tablet {
                        font-size: 1.625rem;
                        padding-top: 1rem;
                    }

                    @include responsive.desktop {
                        font-size: 2.25rem;
                    }
                }
        
                &-email {
                    width: 9.0625rem;
                    text-decoration: none;
                    color: #F1F0EA;
                    font-size: 1rem;

                    @include responsive.tablet {
                        font-size: 1.125rem;
                        width: 10.1875rem;
                    }

                    @include responsive.desktop {
                        font-size: 1.375rem;
                        width: 12.375rem;
                    }
        
                    &::after {
                        content: '';
                        display: block;
                        width: 0;
                        height: 1px;
                        background: #F1F0EA;
                        transition: width .4s ease-in;
                    }
        
                    &:hover::after {
                        width: 100%;
                    }
                }
            }
        
            &__web {
                padding-top: 0;
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                &-title {
                    color: #F1F0EA;
                    font-size: 1.375rem;
                    font-weight: 500;

                    @include responsive.tablet {
                        font-size: 1.625rem;
                        padding-top: 1rem;
                    }

                    @include responsive.desktop {
                        font-size: 2.25rem;
                    }
                }
        
                &__links {
                    padding-top: 1rem;
                    display: flex;
                    flex-direction: column;
                    gap: 1rem;
                    &__ref {
                        display: flex;
                        gap: .625rem;
                        text-decoration: none;
                        &-img {
                            height: 1.5625rem;

                            @include responsive.tablet {
                                height: 1.875rem;
                            }

                            @include responsive.desktop {
                                height: 2.1875rem;
                            }
                        }
        
                        &-website {
                            color: #F1F0EA;
                            font-size: 1rem;

                            @include responsive.tablet {
                                font-size: 1.125rem;
                            }

                            @include responsive.desktop {
                                font-size: 1.375rem;
                            }
        
                            &::after {
                                content: '';
                                display: block;
                                width: 0;
                                height: 1px;
                                background: #F1F0EA;
                                transition: width .4s ease-in;
                            }
        
                            &:hover::after {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}