@use "../../Styles/mixins" as responsive;

.about {
    width: 100%;
    display: flex;
    flex-direction: column;

    &__hero {
        height: 50rem;
        width: 100%;
        background: url("../../assets/images/shooting-star.gif") no-repeat;
        background-size: cover;
        background-position: center;
        overflow: hidden;

        @include responsive.desktop {
            height: 62.5rem
        }
        

        &__scatter {
            padding: 0.5rem;
            width: 100%;
            height: 100%;
            background-color: rgba($color: #000000, $alpha: 0.3);

            @include responsive.tablet {
                padding: 2.25rem;
            }

            @include responsive.desktop {
                padding: 0 5rem;
            }

            &-char {
                position: relative;
                display: inline-block;
                color: #F1F0EA;
                font-weight: 500;

                &.hide {
                    display: none;
                }
            }

            &-title {
                display: none;

                &.display {
                    width: 100%;
                    position: relative;
                    display: inline-block;
                    color: #F1F0EA;
                    font-weight: 500;
                    font-size: 1.4375rem;
                    top: 18.75rem;

                    @include responsive.tablet {
                        font-size: 2.25rem;
                    }

                    @include responsive.desktop {
                        font-size: 4rem;
                        top: 28.125rem;
                    }
                }
            }
        }
    }

    &__details {
        width: 100%;
        padding: 0 1rem;
        display: flex;
        flex-direction: column;
        padding-bottom: 1.5rem;

        @include responsive.tablet {
            flex-direction: column;
            padding: 0 auto;
            padding-bottom: 2.25rem;
        }

        @include responsive.desktop {
            padding: 0 5rem;
            padding-bottom: 3rem;
        }

        &-title {
            text-align: center;
            padding: 1.5rem 0 1rem 0;
            font-size: 2rem;
            font-weight: 700;
            color: #F1F0EA;

            @include responsive.tablet {
                font-size: 3rem;
                padding: 3rem 0 2.25rem 0;
            }

            @include responsive.desktop {
                font-size: 4rem;
            }
        }


        &__bot {
            width: 100%;

            @include responsive.tablet {
                display: flex;
                gap: 24px;
            }

            @include responsive.desktop {
                gap: 1.25rem;
            }

            &__image-container {
                width: 100%;
                height: 18.75rem;
                overflow: hidden;
                border-radius: .625rem;
    
                @include responsive.tablet {
                    width: 50%;
                    height: 40.625rem;
                    margin: auto;
                    overflow: hidden;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                @include responsive.desktop {
                    width: 50%;
                    height: 43.75rem;
                    margin-top: .625rem;
                }
    
                &-img {
                    width: 100%;
                    
                    @include responsive.tablet {
                        object-fit: cover;
                        height: 43.75rem;
                        border-radius: .625rem;
                    }

                    @include responsive.desktop {
                        object-fit: cover;
                        width: 100%;
                        height: 62.5rem;
                        margin-top: 12.5rem;
                    }

                }
            }
    
            &__description__container {
                padding-top: 1.5rem;
    
                @include responsive.tablet {
                    width: 50%;
                    padding-top: 0;
                }

                @include responsive.desktop {
                    width: 50%;
                }
    
                &-title {
                    color: #F1F0EA;
                    font-weight: 500;
                    font-size: 1.5rem;

                    @include responsive.tablet {
                        font-size: 2.25rem;
                    }
                    
                }
    
                &-intro {
                    padding-top: 1rem;
                    color: #F1F0EA;
                    font-size: 1rem;

                    @include responsive.tablet {
                        font-size: 1rem;
                        line-height: 1.375rem;
                        padding-top: 1.25rem;
                    }

                    @include responsive.desktop {
                        font-size: 1.375rem;
                        line-height: 2rem;
                    }
                }
            }
        }

        

    }

    &__skillset {
        width: 95%;
        margin: auto;
        padding: 1.5rem 1rem 1rem 1rem;
        background-color: #A1ACBD;
        border-radius: .5rem;

        @include responsive.tablet {
            width: 90%;
            padding-bottom: 3.75rem;
        }

        @include responsive.desktop {
            padding-bottom: 3.75rem;
        }


        &__stack {
            &__top {
                margin-bottom: 20px;
                position: relative;
                z-index: 1;
                display: flex;
                align-items: center;
                gap: .4375rem;

                @include responsive.tablet {
                    margin-bottom: 2.25rem;
                }


                &::before {
                    content: " ";
                    width: 100%;
                    position: absolute;
                    top: 25px;
                    z-index: -1;
                    border-bottom: 3px solid #171D25;

                    @include responsive.tablet {
                        top: 1.875rem;
                    }

                    @include responsive.desktop {
                        top: 2.3125rem;
                    }
                }

                &-icon {
                    height: 2.1875rem;

                    @include responsive.tablet {
                        height: 3.125rem;
                    }

                    @include responsive.desktop {
                        height: 3.75rem;
                    }
                }

                &-title {
                    color: #171D25;
                    font-size: 1.875rem;
                    font-weight: 700;

                    @include responsive.tablet {
                        font-size: 2.25rem;
                    }

                    @include responsive.desktop {
                        font-size: 3rem;
                    }

                    &::before {
                        content: " ";
                        z-index: -1;
                        height: 100%;
                        width: 12.5rem;
                        padding-right: 10px;
                        display: block;
                        position: absolute;
                        left: 0;
                        top: 0;
                        background-color: rgba($color: #A1ACBD, $alpha: 1);

                        @include responsive.tablet {
                            width: 16.25rem;
                        }

                        @include responsive.desktop {
                            width: 20.625rem;
                        }
                      }
    
                }
            }

            &__bot {
                display: flex;
                flex-direction: column;
                gap: 1.5rem;

                @include responsive.tablet {
                    flex-direction: row;
                    justify-content: space-around;
                    width: 100%;
                }

                @include responsive.desktop {
                    justify-content: space-around;
                    gap: 2.25rem;
                    padding: 0 5rem;
                }
                
                &__front-end {
                    background: rgba( 61, 71, 86, 0.85 );
                    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
                    backdrop-filter: blur( 18.5px );
                    -webkit-backdrop-filter: blur( 18.5px );
                    border-radius: 10px;
                    border: 1px solid rgba( 255, 255, 255, 0.18 );
                    padding: .5rem;
                    
                    @include responsive.tablet {
                        width: 100%;
                    }

                    @include responsive.tablet {
                        width: 100%;
                    }

                    &__top {
                        display: flex;
                        gap: .3125rem;
                        justify-content: center;
                        align-items: center;
                        border-bottom: 1px solid white;
                        padding-bottom: .5rem;

                        @include responsive.desktop {
                            gap: .8125rem;
                        }
                        &-icon {
                            height: 1.875rem;

                            @include responsive.tablet {
                                height: 2.1875rem;
                            }

                            @include responsive.desktop {
                                height: 2.5rem;
                            }
                        }

                        &-title {
                            font-size: 1.5rem;
                            font-weight: 500;
                            color: #F1F0EA;

                            @include responsive.tablet {
                                font-size: 1.625rem;
                            }

                            @include responsive.desktop {
                                font-size: 2.25rem;
                            }
                        }
                    }

                    &__bot {
                        display: flex;
                        flex-direction: column;
                        padding: 1rem 0;
                        &-skills {
                            color: #F1F0EA;
                            font-size: 1.125rem;
                            text-align: center;

                            @include responsive.tablet {
                                font-size: 1.375rem;
                            }

                            @include responsive.desktop {
                                font-size: 1.75rem;
                            }
                        }
                    }
                }

                &__back-end {
                    background: rgba( 61, 71, 86, 0.85 );
                    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
                    backdrop-filter: blur( 18.5px );
                    -webkit-backdrop-filter: blur( 18.5px );
                    border-radius: 10px;
                    border: 1px solid rgba( 255, 255, 255, 0.18 );
                    padding: .5rem;

                    @include responsive.tablet {
                        width: 100%;
                    }

                    @include responsive.tablet {
                        width: 100%;
                    }

                    &__top {
                        display: flex;
                        gap: .3125rem;
                        justify-content: center;
                        align-items: center;
                        border-bottom: 1px solid white;
                        padding-bottom: .5rem;

                        @include responsive.desktop {
                            gap: .8125rem;
                        }

                        &-icon {
                            height: 1.875rem;

                            @include responsive.tablet {
                                height: 2.1875rem;
                            }

                            @include responsive.desktop {
                                height: 2.5rem;
                            }
                        }

                        &-title {
                            font-size: 1.5rem;
                            font-weight: 500;
                            color: #F1F0EA;

                            @include responsive.tablet {
                                font-size: 1.625rem;
                            }

                            @include responsive.desktop {
                                font-size: 2.25rem;
                            }
                        }
                    }

                    &__bot {
                        display: flex;
                        flex-direction: column;
                        padding: 1rem 0;
                        &-skills {
                            color: #F1F0EA;
                            font-size: 1.125rem;
                            text-align: center;

                            @include responsive.tablet {
                                font-size: 1.375rem;
                            }

                            @include responsive.desktop {
                                font-size: 1.75rem;
                            }
                        }
                    }
                }

                &__others {
                    background: rgba( 61, 71, 86, 0.85 );
                    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
                    backdrop-filter: blur( 18.5px );
                    -webkit-backdrop-filter: blur( 18.5px );
                    border-radius: 10px;
                    border: 1px solid rgba( 255, 255, 255, 0.18 );
                    padding: .5rem;

                    @include responsive.tablet {
                         width: 100%;
                    }

                    @include responsive.tablet {
                        width: 100%;
                    }

                    &__top {
                        display: flex;
                        gap: .3125rem;
                        justify-content: center;
                        align-items: center;
                        border-bottom: 1px solid white;
                        padding-bottom: .5rem;

                        @include responsive.desktop {
                            gap: .8125rem;
                        }
                        &-icon {
                            height: 1.875rem;

                            @include responsive.tablet {
                                height: 2.1875rem;
                            }

                            @include responsive.desktop {
                                height: 2.5rem;
                            }
                        }

                        &-title {
                            font-size: 1.5rem;
                            font-weight: 500;
                            color: #F1F0EA;

                            @include responsive.tablet {
                                font-size: 1.625rem;
                            }

                            @include responsive.desktop {
                                font-size: 2.25rem;
                            }
                        }
                    }

                    &__bot {
                        display: flex;
                        flex-direction: column;
                        padding: 1rem 0;
                        &-skills {
                            color: #F1F0EA;
                            font-size: 1.125rem;
                            text-align: center;

                            @include responsive.tablet {
                                font-size: 1.375rem;
                            }

                            @include responsive.desktop {
                                font-size: 1.75rem;
                            }
                        }
                    }
                }
                

            }

        }
    }
}
