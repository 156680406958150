@use "../../Styles/mixins" as responsive;

.header {
    height: 3.75rem;
    width: 100%;
    position:fixed;
    top: 0;
    z-index: 1000;
    background-color: #171D25;


    @include responsive.tablet {
        height: 5rem;
    }

    &__nav {
        display: flex;
        justify-content: space-between;
        align-content: center;
        padding: .5rem;
        height: 100%;

        @include responsive.tablet {
            padding: 1rem;
        }

        &__left {
            display: flex;
            align-items: center;
            width: 60%;
            justify-content: left;
            gap: .3125rem;
            &-logo {
                height: 3.125rem;

                @include responsive.tablet {
                    height: 3.75rem;
                }

                @include responsive.desktop {
                    height: 4.375rem;
                }
            }

            &-title {
                margin: 0;
                height: 2.5rem;
                font-size: 1.125rem;
                font-weight: 700;
                color: #F1F0EA;
                margin-top: .625rem;

                @include responsive.tablet {
                    font-size: 1.25rem;
                }

                @include responsive.desktop {
                    font-size: 1.625rem;
                    margin-top: 0;
                }
            }
        }

        &__right {
            margin-top: .1875rem;

            @include responsive.tablet {
                margin: 0;
            }

            &__hamburger {
                position: relative;
                display: block;
                width: 2.1875rem;
                height: 2.1875rem;
                cursor: pointer;
                appearance: none;
                background: none;
                outline: none;
                border: 1px solid #F1F0EA;
                padding: 0 .5rem;
                border-radius: .5rem;

                @include responsive.tablet {
                    display: none;
                }

                &:hover {
                    background-color: #A1ACBD;
                }

                &-bar, &::before, &::after {
                    content: ' ';
                    display: block;
                    width: 100%;
                    height: 2px;
                    background-color: #F1F0EA;
                    margin: 2px 0;
                    transition: 0.4s;
                }

                &.active {
                    background-color: #A1ACBD;
                }
            }

            &__hamburger.active::before {
                transform: rotate(-45deg) translate(-2px, 3px);
            }

            &__hamburger.active::after {
                transform: rotate(45deg) translate(-2px, -4px);
            }

            &__hamburger.active .header__nav__right__hamburger-bar {
                opacity: 0;
            }

            &__tablet-nav {
                display: none;

                @include responsive.tablet {
                    display: flex;
                    width: 18.75rem;
                    height: 100%;
                }

                @include responsive.desktop {
                    width: 21.875rem;
                }

                &__list {
                    width: 100%;
                    height: 100%;
                    list-style: none;
                    display: flex;
                    justify-content: space-evenly;

                    &__item {
                        margin: auto;

                        &-link {
                            color: #F1F0EA;
                            text-decoration: none;
                            font-size: 1.125rem;

                            @include responsive.desktop {
                                font-size: 1.25rem;
                            }

                            &::after {
                                content: '';
                                display: block;
                                width: 0;
                                height: 2px;
                                background: #F1F0EA;
                                transition: width .4s ease-in;
                            }
                
                            &:hover::after {
                                width: 100%;
                            }
                        }
                    }
                }

            }


        }

        &__container {
            display: none;

            &__menu {
                height: 7.8125rem;
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                padding-left: 1rem;

                &__list {
                    list-style: none;
                    color: #171D25;

                    &-link {
                        color: #171D25;
                        text-decoration: none;
                        position: relative;

                        &::after {
                            content: '';
                            display: block;
                            position: absolute;
                            width: 0;
                            height: 2px;
                            background: #171D25;
                            transition: width .4s ease-in;
                        }
            
                        &:hover::after {
                            width: 100%;
                        }
                    }
                }
            }
        }

        &__container.active {
            display: block;
            position: absolute;
            right: 0;
            padding: 0.5rem;
            height: 9.375rem;
            width: 9.375rem;
            transform: translate(-0.5rem , 3.75rem);
            border: 2px solid #171D25;
            border-radius: .5rem;
            background-color: #A1ACBD;
        }



    }
}