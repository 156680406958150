@font-face {
    font-family: 'Oswald';
    src: url("../assets/fonts/Oswald-Regular.ttf") format('truetype');
    font-weight: 400;
}

@font-face {
    font-family: 'Oswald';
    src: url("../assets/fonts/Oswald-SemiBold.ttf") format('truetype');
    font-weight: 500;
}

@font-face {
    font-family: 'Oswald';
    src: url("../assets/fonts/Oswald-Bold.ttf") format('truetype');
    font-weight: 700;
}